import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import Form from "../components/form"
import { Social } from "../components/social"
import "../styles/index.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Container>
      <div className="profile-image"></div>
      <h1>I build web apps.</h1>
      <h3>Hi there! My name is Ashish, and I'm a software developer.</h3>
      <p>
        I have a passion for building things and writing scalable, secure,
        reliable &amp; readable code.
      </p>
      <Social />
      <Form title={"Get In Touch"} isNewsletter={false} name="Contact" />
    </Container>
  </Layout>
)

export default IndexPage
